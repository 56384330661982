








import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')
let lpTag: any
@Component({
  components: {
    PageLayout
  }
})
export default class PreTest extends Vue {
  private lessHeight = false
  private mounted (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('less-height', () => {
        this.lessHeight = true
      })
    }
    // const user = this.$store.getters['walkthrough/userInfo']
    // const ctu = localStorage.getItem('ctu') as string
    // lpTag = (window as unknown as any).lpTag
    // const sdes = [
    //   {
    //     type: 'ctmrinfo',
    //     info: {
    //       customerId: this.$store.state.auth.userId,
    //       userName: user.fullName,
    //       registrationDate: {
    //         day: moment(user.flyingDate).format('DD'),
    //         month: moment(user.flyingDate).format('MM'),
    //         year: moment(user.flyingDate).format('YYYY')
    //       },
    //       socialId: this.$store.state.auth.userId,
    //       imei: ctu,
    //       storeNumber: user.passportNumber
    //     }
    //   },
    //   {
    //     type: 'personal',
    //     personal: {
    //       contacts: [
    //         { email: this.$store.state.auth.email }
    //       ]
    //     }
    //   }
    // ]
    // lpTag.sdes.push(sdes)
  }

  private destroyed (): void {
    if (window.innerWidth < 767) {
      this.$root.$off('less-height')
    }
  }
}
